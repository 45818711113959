$device-width-s: 768px;
$device-width-s-max: $device-width-s - 1px;
$device-width-m: 1024px;
$device-width-m-max: $device-width-m - 1px;
$device-width-l: 1280px;
$device-width-l-max: $device-width-l - 1px;
.header-sub-menu {
  position: absolute;
  top: 0;
  width: calc(var(--main-level-width) - var(--space-500) * 2);
  display: flex;
  flex-direction: column;
  margin: 0 var(--space-500);
  visibility: hidden;
  transition: visibility 0.6s cubic-bezier(0.405, 0.005, 0, 1);

  @media (min-width: 1280px) {
    transition: visibility 0s;
    width: calc(var(--sub-level-width) - var(--space-500) * 2);
  }
}

.header-menu-overlay--active
  .header-main-menu-item--expanded
  .header-sub-menu--active {
  @media (min-width: 1280px) {
    visibility: visible;
  }
}

.header-menu-overlay--active
  .header-main-menu-item--expanded
  .header-sub-menu--last.header-sub-menu--active {
  @media (max-width: 1279px) {
    visibility: visible;
  }
}

.header-sub-menu--level-1 {
  left: var(--main-level-width);
  top: var(--space-200);
}

.header-sub-menu--level-2,
.header-sub-menu--level-3 {
  left: calc(var(--main-level-width) - var(--space-500));
  @media (min-width: 1280px) {
    left: calc(var(--sub-level-width) - var(--space-500));
  }
}

.header-sub-menu-item > a,
.header-sub-menu-item > button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--color-text);
  font-family: var(--font-family-body);
  font-size: 2rem;
  padding: var(--space-200);
  text-align: left;

  .no-touch &:hover {
    background-color: var(--color-quartz-200);
  }

  .no-touch .header-sub-menu--last &:hover {
    background-color: var(--color-background-surface-primary);
  }
}

.header-sub-menu-item--expanded > button::before {
  content: '';
  position: absolute;
  bottom: calc(var(--space-100) + 1px);
  left: calc(var(--space-200) + 1px);
  transform: translateY(-50%);
  width: 40px;
  height: 0;
  border-bottom: 1px solid var(--color-text-subtle);
}

.header-sub-menu-back-button {
  display: flex;
  align-items: center;
  gap: var(--space-300);
  margin: 0 0 var(--space-500) var(--space-200);
  font: var(--font-body-m);

  @media (min-width: 1280px) {
    display: none;
  }
}
