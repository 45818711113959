$device-width-s: 768px;
$device-width-s-max: $device-width-s - 1px;
$device-width-m: 1024px;
$device-width-m-max: $device-width-m - 1px;
$device-width-l: 1280px;
$device-width-l-max: $device-width-l - 1px;
.header-menu {
  --main-level-width: 100vw;
  --sub-level-width: 325px;

  @media (min-width: 768px){
    --main-level-width: 400px;
  }

  -webkit-tap-highlight-color: transparent;

  button,
  a {
    -webkit-tap-highlight-color: initial;
  }
}

.header-menu-button {
  display: flex;
}

.header--overlayed .header-hamburger-icon {
  color: var(--color-white);
}

.header-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  z-index: 1000;
  background-color: transparent;
  visibility: hidden;
  transition: all 0.6s cubic-bezier(0.405, 0.005, 0, 1);
}

.header-menu-overlay--active {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}

.header-menu-close-button {
  display: flex;
  position: absolute;
  top: var(--space-500);
  right: var(--space-500);
  z-index: 100;
}

.header-menu-container {
  position: relative;
  height: 100vh;
  background: var(--color-white);
  width: var(--main-level-width);
  transform: translateX(-100%);
  transition: all 0.6s cubic-bezier(0.405, 0.005, 0, 1), background 0s;
  overflow: hidden;
  padding-top: var(--space-900);

  @media (min-width: 768px) {
    padding-top: var(--space-1000);
  }

  @media (min-width: 768px) and (max-height: 600px) {
    padding-top: var(--space-800);
  }

  .header-menu-overlay--active & {
    transform: translateX(0);
  }
}

.header-menu-overlay--active .header-menu-container--level-1 {
  @media (min-width: 1280px) {
    transform: translateX(0);
    width: calc(var(--main-level-width) + var(--sub-level-width));
    background: linear-gradient(
      90deg,
      var(--color-background-surface-primary) var(--main-level-width),
      var(--color-white) calc(var(--sub-level-width))
    );
  }
}

.header-menu-overlay--active .header-menu-container--level-2 {
  @media (min-width: 1280px) {
    width: calc(var(--main-level-width) + var(--sub-level-width) * 2);
    background: linear-gradient(
      90deg,
      var(--color-background-surface-primary)
        calc(var(--main-level-width) + var(--sub-level-width)),
      var(--color-white) var(--sub-level-width)
    );
  }
}

.header-menu-overlay--active .header-menu-container--level-3 {
  @media (min-width: 1280px) {
    width: calc(var(--main-level-width) + var(--sub-level-width) * 3);
    background: linear-gradient(
      90deg,
      var(--color-background-surface-primary)
        calc(var(--main-level-width) + var(--sub-level-width) * 2),
      var(--color-white) var(--sub-level-width)
    );
  }
}

.header-menu-links {
  position: relative;
  transition: all 0.6s cubic-bezier(0.405, 0.005, 0, 1);
  
  @media (max-width: 1279px) {
    .header-menu-container--level-1 & {
      transform: translateX(calc(var(--main-level-width) * -1));
      width: calc(var(--main-level-width) * 2);
    }
    .header-menu-container--level-2 & {
      transform: translateX(calc(var(--main-level-width) * -2));
      width: calc(var(--main-level-width) * 3);
    }
    .header-menu-container--level-3 & {
      transform: translateX(calc(var(--main-level-width) * -3));
      width: calc(var(--main-level-width) * 4);
    }
  }
}

.header-main-menu {
  display: flex;
  flex-direction: column;
  width: calc(var(--main-level-width) - var(--space-500) * 2);
  margin: 0 var(--space-500);

  @media (min-width: 768px) {
    gap: var(--space-200);
  }
}

.header-main-menu-item > a,
.header-main-menu-item > button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--space-400);
  width: 100%;
  color: var(--color-text);
  font-family: var(--font-family-headline);
  font-size: 3.2rem;
  text-transform: uppercase;
  padding: var(--space-300);

  .no-touch &:hover {
    background-color: var(--color-background-surface-primary);
  }

  .no-touch [class*=" header-menu-container--level-"] &:hover {
    background-color: var(--color-quartz-200);
  }

  @media (max-width: $device-width-s-max) {
    font-size: 2.8rem;
  }

  @media (max-width: 1279px) {
    transition: visibility 0.6s cubic-bezier(0.405, 0.005, 0, 1);
    [class*=" header-menu-container--level-"] & {
      visibility: hidden;
    }
  }
}

.header-main-menu-item--expanded > button::before {
  content: '';
  position: absolute;
  bottom: var(--space-200);
  left: calc(var(--space-300) + 1px);
  transform: translateY(-50%);
  width: 40px;
  height: 0;
  border-bottom: 1px solid var(--color-text-subtle);
}

.header-menu-divider {
  height: 1px;
  background-color: var(--color-text-subtle);
  width: 80px;
  margin: var(--space-500) 0 var(--space-500)
    calc(var(--space-500) + var(--space-300));
}

.header-secondary-menu {
  display: flex;
  flex-direction: column;
  width: calc(var(--main-level-width) - var(--space-500) * 2);
  margin: 0 var(--space-500);

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: var(--color-text);
    font-family: var(--font-family-body);
    font-size: 2rem;
    padding: var(--space-200);

    .no-touch &:hover {
      background-color: var(--color-background-surface-primary);
    }

    .no-touch [class*=" header-menu-container--level-"] &:hover{
      background-color: var(--color-quartz-200);
    }
  }

  @media (max-width: 1279px) {
    [class*=" header-menu-container--level-"] & a {
      visibility: hidden;
      transition: visibility 0.6s cubic-bezier(0.405, 0.005, 0, 1);
    }
  }
}
